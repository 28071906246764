<template>
  <div style="width: 100%; margin-left: 1rem; margin-right: 1rem">
    <a-tabs :default-active-key="defaultTabActive">
      <a-tab-pane :key="1" :tab="$t('store.allProduct')" />
      <!-- <a-tab-pane :key="2" tab="Best Seller Product" />
      <a-tab-pane :key="3" tab="Discounted Product" /> -->
      <div v-if="$store.getters['user/isDropship']" slot="tabBarExtraContent">
        <a-checkbox
          @change="onCheckAllChange"
          :indeterminate="indeterminate"
          :checked="checkedAll"
        >
          {{ $t('store.selectAll') }}
        </a-checkbox>
        <a-button
          :disabled="disableButtonBulkRemove"
          @click="() => removeFromStrore()"
        >
          {{ $t('store.removeFromStore') }}
        </a-button>
      </div>
    </a-tabs>
    <a-row>
      <a-col :xs="24" :md="12" :lg="9" class="order-2 mt-3">
        <a-input-search
          :placeholder="$t('store.searchProduct')"
          @change="onQueryChanged"
        />
      </a-col>
    </a-row>
    <a-row class="mt-3" type="flex" justify="space-between">
      <a-col :xs="24" :md="12" :lg="6">
        <a-form :form="filterForm">
          <a-form-item label="Sort By">
            <a-select
              v-decorator="['filter']"
              style="width: 100%"
              placeholder="Sort By"
              @change="handleFilterChange"
            >
              <a-select-option
                v-for="(item, index) in filter"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :xs="24" :md="12" :lg="6">
        <a-form :form="sortForm">
          <a-form-item label="Order By">
            <a-select
              v-decorator="['sort']"
              style="width: 100%"
              placeholder="Order By"
              @change="handleSortChange"
            >
              <a-select-option
                v-for="(item, index) in sort"
                :key="index"
                :value="item.value"
              >
                <a-icon slot="suffixIcon" :type="item.icon" />
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
    <div
      v-if="!products.length && !loading"
      class="d-flex justify-content-center"
    >
      <div class="text-bold" style="font-size: 30px">
        <strong> {{ $t('store.productNotFound') }} </strong>
      </div>
    </div>
    <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mx-n2">
      <div class="col px-2" v-for="(product, index) in products" :key="index">
        <ItemGrid
          :product="product"
          :index="index"
          :checkedAll="checkedAll"
          :dataChecked="dataChecked"
          :isPorductInStore="true"
          @removeFromStrore="removeFromStrore"
          @addData="addData"
          @deleteData="deleteData"
          @stock-updated="fetchProducts"
          :loading="loading"
        />
      </div>
    </div>
    <div
      class="py-5 d-flex justify-content-center align-items-center"
      v-if="totalProducts !== products.length"
    >
      <a-button @click.prevent="loadMore" :loading="loading" size="large">
        {{ $t('store.loadMore') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ItemGrid from '@/components/Product/ItemGrid.vue'

const filter = [
  {
    label: 'New Product',
    value: 'newproduct',
  },
  {
    label: 'Featured Product',
    value: 'featured',
  },
  {
    label: 'Special Product',
    value: 'special',
  },
  {
    label: 'Best Seller Product',
    value: 'bestseller',
  },
  {
    label: 'Product Name',
    value: 'product_name',
  },
  {
    label: 'Commission',
    value: 'commission',
  },
  {
    label: 'Discount',
    value: 'discount',
  },
  {
    label: 'Price',
    value: 'price',
  },
  {
    label: 'Stock',
    value: 'stock',
  },
  {
    label: 'Sold Item',
    value: 'sold',
  },
]
const sort = [
  {
    label: 'ASC',
    value: 'asc',
    icon: 'caret-up',
  },
  {
    label: 'DESC',
    value: 'desc',
    icon: 'caret-down',
  },
]

export default {
  data: function () {
    return {
      defaultTabActive: 1,
      timeout: 0,
      page: 1,
      limit: 25,
      dataChecked: [],
      checkedAll: false,
      indeterminate: false,
      disableButtonBulkRemove: true,
      filter,
      filterForm: this.$form.createForm(this),
      filterBy: null,
      sort,
      sortForm: this.$form.createForm(this),
      sortBy: null,
      querySearch: null,
    }
  },
  components: {
    ItemGrid,
  },
  methods: {
    fetchProducts() {
      this.$store.dispatch('product/GETPRODUCTINSTORE', {
        page: this.page,
        limit: this.limit,
        sort: this.filterBy,
        order: this.sortBy,
        q:
          this.querySearch && this.querySearch.length
            ? this.querySearch
            : undefined,
      })
    },
    onQueryChanged(e) {
      const value = e.target.value
      this.querySearch = e.target.value
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        // this.filterForm.resetFields('filter')
        // this.sortForm.resetFields('sort')
        this.filterForm.resetFields()
        this.sortForm.resetFields()
        this.filterBy = null
        this.sortBy = null
        this.$store.dispatch('product/GETPRODUCTINSTORE', { q: value })
      }, 1000)
    },
    loadMore() {
      this.page += 1
      this.fetchProducts()
    },
    removeFromStrore(value) {
      if (value) {
        this.dataChecked = []
        this.dataChecked.push(value)
      }
      this.$store
        .dispatch('product/DELETEFROMSTORE', this.dataChecked)
        .then((_) => {
          return this.$store.dispatch('product/GETPRODUCTINSTORE', {
            page: 1,
            limit: this.limit * this.page,
            sort: this.filterBy ? this.filterBy : null,
            order: this.sortBy ? this.sortBy : null,
            q: this.querySearch ? this.querySearch : null,
          })
        })
        .then((_) => {
          this.dataChecked = []
          this.checkedAll = false
          this.indeterminate = false
          this.disableButtonBulkRemove = true
          this.$notification.success({
            message: this.$t('store.successRemoveProduct'),
          })
        })
        .catch((err) => {
          this.$notification.error({
            message: this.$t('store.failedRemoveProduct'),
            description: err.response?.data?.message || err.message,
          })
        })
    },
    handleFilterChange(value) {
      this.filterBy = value
      this.page = 1
      this.$store.dispatch('product/GETPRODUCTINSTORE', {
        page: this.page,
        limit: this.limit,
        sort: this.filterBy,
        order: this.sortBy ? this.sortBy : null,
        q: this.querySearch ? this.querySearch : null,
      })
    },
    handleSortChange(value) {
      this.sortBy = value
      this.page = 1
      this.$store.dispatch('product/GETPRODUCTINSTORE', {
        page: this.page,
        limit: this.limit,
        sort: this.filterBy ? this.filterBy : null,
        order: this.sortBy,
        q: this.querySearch ? this.querySearch : null,
      })
    },
    onCheckAllChange(e) {
      this.checkedAll = e.target.checked
      if (e.target.checked) {
        this.indeterminate = false
        this.dataChecked = []
        this.disableButtonBulkRemove = false
        this.products.map((item) => this.dataChecked.push(item.product_id))
      } else {
        this.disableButtonBulkRemove = true
        this.dataChecked = []
      }
    },
    addData(param) {
      this.dataChecked.push(param)
      if (this.products.length === this.dataChecked.length) {
        this.indeterminate = false
        this.checkedAll = true
        this.disableButtonBulkRemove = false
      } else if (
        !!this.dataChecked.length &&
        this.dataChecked.length < this.products.length
      ) {
        this.checkedAll = false
        this.indeterminate = true
        this.disableButtonBulkRemove = false
      }
    },
    deleteData(param) {
      this.dataChecked = this.dataChecked.filter((item) => item !== param)
      if (this.products.length === this.dataChecked.length) {
        this.indeterminate = false
        this.checkedAll = true
        this.disableButtonBulkRemove = false
      } else if (
        !!this.dataChecked.length &&
        this.dataChecked.length < this.products.length
      ) {
        this.checkedAll = false
        this.indeterminate = true
        this.disableButtonBulkRemove = false
      } else if (!this.dataChecked.length) {
        this.checkedAll = false
        this.indeterminate = false
        this.disableButtonBulkRemove = true
      }
    },
  },
  computed: {
    ...mapState('product', {
      loading: (state) => state.loading,
      products: (state) => state.productsInStore,
      totalProducts: (state) => state.totalMyProducts,
    }),
  },
  created() {
    this.fetchProducts()
  },
}
</script>
