<template>
  <div class="mb-5 product-item">
    <div class="image text-center">
      <router-link
        :to="{ name: 'product.detail', params: { id: product.product_id } }"
      >
        <img
          :src="`${product.showimg_url}?tr=w-200,h-200,cm-pad_resize,bg-FFFFFF`"
          :alt="product.title"
          class="w-100"
          v-bind:style="(!product.in_stock || (!product.stock_qty && isDistributor)) && 'opacity: 0.3'"
        />
      </router-link>
      <div class="tape" v-if="product.in_store === 1 && !isPorductInStore">
        <a-icon type="check-circle" />
        {{ $t('store.alreadyInStore') }}
      </div>
      <div
        class="checkbox"
        v-if="
          product.in_store === 0 ||
          (isPorductInStore && isDropship)
        "
      >
        <a-checkbox class="box" @change="handleChecked" :checked="checked" />
      </div>
      <div class="stock" v-if="!product.in_stock || (!product.stock_qty && isDistributor)">
        <span>{{ $t('store.outOfStock') }}</span>
      </div>
    </div>
    <div class="px-3">
      <div class="my-3 text-left" style="height: 34px; line-height: 1.2; overflow: hidden; text; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">
        <strong> {{ product.title }} </strong>
      </div>
      <div class="text-left mb-1">
        <div class="text-muted">
          {{ $t('store.price', { value: product.variant_count }) }}
        </div>
        <div>
          {{ sellingPrice }}
        </div>
      </div>
      <div class="text-left mt-1" v-if="isDropship">
        <div class="text-muted">{{ $t('store.commission') }}</div>

        <div>{{ commissionPrice }}</div>
      </div>
      <div class="text-left mt-1" v-if="isDistributor">
        <div class="text-muted">{{ $t('store.stock') }}</div>
        <div>{{ product.stock_qty }}</div>
      </div>
      <div class="text-left pt-4" v-if="isPorductInStore && isDropship">
        <!-- <div class="text-muted" :style="{cursor: 'pointer'}" @click.prevent="shareProduct">
          {{ $t('store.shareThisProduct') }} <a-icon type="share-alt" />
        </div> -->
        <a-button @click.prevent="shareProduct">
          <strong>
            <a-icon type="share-alt" class="mr-2" />
            {{ $t('store.shareThisProduct') }}
          </strong>
        </a-button>
        <a-modal
          :title="$t('store.shareProduct', { value: product.title })"
          :visible="visible"
          :footer="null"
          @cancel="() => (visible = false)"
        >
          <SocialSharing
            :id="product.product_id"
            :name="product.title"
            :price="sellingPrice"
          />
        </a-modal>
        <!-- <SocialSharing
          :id="product.product_id"
          :name="product.title"
        /> -->
      </div>
      <div class="text-center py-3" v-if="product.in_store === 0">
        <a-button size="" type="primary" @click.prevent="addToStore" class="d-block w-100">
          {{ $t('store.addToStore') }}
        </a-button>
      </div>
      <div
        class="text-center py-3"
        v-if="product.in_store === 1 && isDropship"
      >
        <a-button
          size=""
          type="danger"
          class="d-block w-100"
          :loading="loadingRemove"
          @click.prevent="removeFromStrore"
        >
          {{ $t('store.removeFromStore') }}
        </a-button>
      </div>
    </div>
    <div class="text-center py-2 px-2" v-if="isDistributor">
      <div>
        <a-button
          type="primary"
          class="d-block w-100"
          @click.prevent="stockFormVisibility = true"
        >
          Update Stock
        </a-button>
      </div>

      <UpdateStockForm
        v-if="stockFormVisibility"
        @closed="stockFormVisibility = false"
        :product-id="product.product_id"
        close-on-submit
        @saved="$emit('stock-updated')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SocialSharing from '@/components/SocialSharing/index.vue'
import UpdateStockForm from './UpdateStockForm.vue'

export default {
  name: 'ProductitemGrid',
  components: {
    SocialSharing,
    UpdateStockForm,
  },
  props: {
    product: {
      type: Object,
    },
    index: {
      type: Number,
    },
    checkedAll: {
      type: Boolean,
    },
    dataChecked: {
      type: Array,
    },
    isPorductInStore: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      checked: false,
      loadingRemove: false,
      visible: false,
      stockFormVisibility: false,
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters('user', ['isDropship', 'isDistributor']),

    /**
     * @returns {string}
     */
    sellingPrice() {
      return this.priceText(this.product.min_sellingprice, this.product.max_sellingprice)
    },

    /**
     * @returns {string}
     */
    commissionPrice() {
      return this.priceText(this.product.min_commission, this.product.max_commission)
    },
  },
  watch: {
    checkedAll: function (newVal) {
      if (newVal) {
        const findData = this.dataChecked.find(
          (item) => item === this.product.product_id,
        )
        if (findData) this.checked = true
      } else {
        const findData = this.dataChecked.find(
          (item) => item === this.product.product_id,
        )
        if (!findData) this.checked = false
      }
    },
    loading: function (newVal) {
      if (newVal) {
        const findData = this.dataChecked.find(
          (item) => item === this.product.product_id,
        )
        if (findData) {
          this.loadingRemove = true
        }
      } else {
        this.loadingRemove = false
      }
    },
  },
  methods: {
    priceText(min, max) {
      const currency = this.$options.filters.currency
      const minPrice = Number(min)
      const maxPrice = Number(max)
      const prices = [currency(minPrice)]

      if (min > maxPrice) {
        prices.push(currency(maxPrice))
      }

      return prices.join(' - ')
    },
    handleChecked(e) {
      if (this.checked && !e.target.checked) {
        this.checked = false
        // this.$emit('updateChecked', false)
        this.$emit('deleteData', this.product.product_id)
      } else {
        this.$emit('addData', this.product.product_id)
        this.checked = true
      }
    },
    addToStore() {
      this.$emit('addToStore', this.product.product_id)
    },
    removeFromStrore() {
      this.$emit('removeFromStrore', this.product.product_id)
    },
    shareProduct() {
      this.$store
        .dispatch('product/GETPRODUCTDETAIL', this.product.product_id)
        .then((_) => {
          this.visible = true
        })
    },
  },
}
</script>

<style scoped lang="scss">
.product-item {
  background: #fff;
}

.image {
  // border: 1px solid #eaeaea;
  // padding: 1rem;
  position: relative;
}

.image .overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  // -webkit-filter: drop-shadow(0 1px 1px hsla(0, 0%, 0%, 0.3));
  pointer-events: none;
  background-color: rgba(192, 13, 13, 0.2);
  box-shadow: inset 0 0 1em 0.5em hsla(0, 0%, 100%, 0.1);
}

.image .checkbox {
  position: absolute;
  top: 8px;
  right: 16px;
}

.image .checkbox .box {
  -ms-transform: scale(1.3); /* IE */
  -moz-transform: scale(1.3); /* FF */
  -webkit-transform: scale(1.3); /* Safari and Chrome */
  -o-transform: scale(1.3); /* Opera */
  transform: scale(1.3);
}

.tape {
  background-color: #41b883;
  position: absolute;
  text-align: left;
  top: .5rem;
  left: 0;
  color: rgb(255, 255, 255);
  padding: .25rem 1rem;
  display: flex;
  align-items: center;

  .anticon {
    margin-right: .5rem;
    font-size: 18px;
    margin-bottom: .1rem;
  }
}
.stock {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  /* left: 50%; */

  > span {
    padding: .5rem 1.5rem;
    background-color: #d6d6d6;
    color: rgb(85, 85, 85);
    // box-shadow: inset 0 0 1em 0.5em hsla(0, 0%, 100%, 0.1);
  }
}
.stock:after,
.stock:before {
  background-size: 0.4em 0.4em;
  bottom: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 50%;
  right: 50%;
  width: 0.2em;
}
</style>
