<template>
  <svg viewBox="0 0 512 512" width="1.3em" height="1.3em">
    <path
      d="M432 256h-80v-64c0-17.664 14.336-16 32-16h32V96h-64c-53.024 0-96 42.976-96 96v64h-64v80h64v176h96V336h48l32-80z"
      fill="#4267B2"
    />
  </svg>
</template>

<script>
export default {
  name: 'FacebookSvg',
}
</script>
