<template>
  <MyProducts />
</template>

<script>
import MyProducts from '@/components/MyProducts'

export default {
  components: {
    MyProducts,
  },
}
</script>
