<template>
  <a-modal
    :title="$t('store.updateStock')"
    :visible="visible"
    :confirm-loading="loading || fetching"
    @cancel="close"
    :footer="false"
  >
    <div class="d-flex justify-content-center">
      <a-spin :spinning="fetching" />
    </div>

    <div v-if="!!product" class="update-stock-content">
      <div class="d-flex align-items-start">
        <img :src="`${product.thumbimg_url}?tr=w-55,h-55,cm-pad_resize,bg-FFFFFF`" class="mr-3 img-thumbnail" style="width: 55px" />
        <div class="font-weight-bold flex-grow-1 py-2" style="font-size: 1.1rem; line-height: 1.2">{{ product.title }}</div>
      </div>

      <div class="mt-3">
        <table class="table">
          <tr>
            <th v-if="colorOptions.length">{{ $t('store.color') }}</th>
            <th v-if="sizeOptions.length">{{ $t('store.size') }}</th>
            <th v-if="flavorOptions.length">{{ $t('store.flavour') }}</th>
            <th width="100">{{ $t('store.stock') }}</th>
          </tr>
          <tr v-for="catalogue in catalogues" :key="catalogue.catalog_id">
            <td v-if="colorOptions.length">{{ catalogue.option[0] }}</td>
            <td v-if="sizeOptions.length">{{ catalogue.option[1] }}</td>
            <td v-if="flavorOptions.length">{{ catalogue.option[2] }}</td>
            <td>
              <a-input-number size="small" :min="0" :default-value="0" v-model="catalogue.stock" />
            </td>
          </tr>
        </table>
      </div>

      <div class="mt-3 pt-3  d-flex">
        <a-button :loading="loading" class="ml-auto" type="primary" @click="submit">{{ $t('store.saveChanges') }}</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import { apiClientFastify } from '@/services/axios'
import { getOptions } from '@/services/product'

export default {
  props: {
    productId: {
      type: String,
    },
    closeOnSubmit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fetching: true,
      loading: false,
      visible: true,
      product: undefined,
      catalogues: [],
    }
  },
  computed: {
    ...mapState('user', {
      channelId: (state) => state.store_channelid,
    }),

    // channel_id() {
    //   // TODO: HARD CODED SHOULD CHANGE LATER
    //   return 36
    // },

    /**
     * @returns {any[]}
     */
    catalogList() {
      return this.product.catalog_list || []
    },

    /**
     * @returns {string[]}
     */
    colorOptions() {
      return Object.keys(getOptions(this.catalogList || [], 0))
    },

    /**
     * @returns {string[]}
     */
    sizeOptions() {
      return Object.keys(getOptions(this.cataloglist || [], 1))
    },

    /**
     * @returns {string[]}
     */
    flavorOptions() {
      return Object.keys(getOptions(this.cataloglist || [], 2))
    },
  },
  watch: {
    product() {
      const product = this.product

      this.catalogues = [
        ...(
          !product ? [] : product.catalog_list.map(({ inventories, ...catalog }) => {
            const inventory = inventories.length ? inventories[0] : {}
            return {
              ...catalog,
              inventory,
              stock: inventory.item_sellable || 0,
            }
          })
        ),
      ]
    },
  },
  mounted() {
    this.fetchProductDetail()
  },
  destroyed() {
    this.$emit('closed')
  },
  methods: {
    async fetchProductDetail() {
      this.fetching = true
      try {
        // await this.$store.dispatch('product/GETPRODUCTDETAIL', this.productId)
        const { data: response } = await apiClientFastify.get(`/products/private/channel/${this.channelId}/${this.productId}`)
        this.product = response.data
      } catch (err) {
        this.$notification.error({
          message: 'Oops! Something went wrong',
          description: err?.response?.data?.message || err?.response?.message || err?.message,
        })
        this.close()
      }

      this.fetching = false
    },
    close() {
      this.visible = false
      setTimeout(() => this.$destroy(), 150)
    },
    async submit() {
      this.loading = true
      const catalogues = this.catalogues

      try {
        await apiClientFastify.put(
          `/products/private/update_stock/${this.channelId}`,
          catalogues.map((catalogue) => ({
            catalog_uuid: catalogue.uuid,
            new_stock: catalogue.stock,
          })),
        )

        this.$notification.success({
          message: this.$t('store.changeStockSuccess'),
        })

        this.$emit('saved')
        this.$nextTick(() => this.close())
      } catch (err) {
        this.$notification.error({
          message: 'Oops! Something went wrong',
          description: err.response?.data?.message || err.message,
        })
      }

      this.loading = false
    },
  },
}
</script>
