<template>
  <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
    <path
      d="M12 .5C5.385.5 0 4.898 0 10.303c0 4.841 4.27 8.897 10.035 9.668.391.083.923.26 1.058.594.12.303.079.771.038 1.087l-.164 1.026c-.045.303-.24 1.193 1.049.649 1.291-.542 6.916-4.104 9.436-7.019 1.724-1.9 2.548-3.847 2.548-6.005C24 4.898 18.615.5 12 .5zM7.303 13.517H4.917a.636.636 0 01-.63-.633V8.083c0-.347.285-.634.63-.634.348 0 .63.287.63.634v4.167h1.756a.634.634 0 010 1.267zm2.466-.633a.634.634 0 01-.631.633.633.633 0 01-.627-.633V8.083c0-.347.282-.634.63-.634.346 0 .628.287.628.634zm5.741 0a.633.633 0 01-1.141.379l-2.443-3.338v2.958a.632.632 0 01-.631.633.632.632 0 01-.626-.633v-4.8a.63.63 0 01.624-.632c.195 0 .375.105.495.256l2.462 3.351V8.083c0-.347.282-.634.63-.634.345 0 .63.287.63.634zm3.855-3.035a.634.634 0 010 1.269H17.61v1.132h1.755a.633.633 0 010 1.267h-2.386a.633.633 0 01-.627-.633V8.083c0-.347.282-.634.63-.634h2.386c.346 0 .627.287.627.634a.63.63 0 01-.63.634H17.61v1.132z"
      fill="#4caf50"
    />
  </svg>
</template>

<script>
export default {
  name: 'LineSvg',
}
</script>
