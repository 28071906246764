<template>
  <div>
    <div class="mb-3">
      Default Message:
    </div>
    <div class="pr-2 pl-2 mb-3 text-muted">
      {{ sharingDescription }}
    </div>
    <div class="mt-1 mb-3">
      {{ $t('store.changeDefaultMessage') }}
    </div>
    <div>
      <!-- <a-form-item
        :extra="$t('store.extraDefaultMessage')"
      > -->
        <a-textarea
          v-model="value"
          :placeholder="$t('store.placeholderDefaultMessage')"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      <!-- </a-form-item> -->
    </div>
    <div class="mt-3">
      <div class="mb-2"> <strong> {{ $t('store.shareWith') }} </strong> </div>
      <ShareNetwork
        v-for="network in networks"
        :network="network.network"
        :key="network.network"
        :url="sharingUrl"
        :title="sharingTitle"
        :description="sharingDescription"
        class="pr-4"
      >
        <TelegramSvg v-if="network.name === 'Telegram'" />
        <WhatsappSvg v-if="network.name === 'Whatsapp'" />
        <FacebookSvg v-if="network.name === 'Facebook'" />
        <LineSvg v-if="network.name === 'Line'" />
        <!-- <span class="mr-3">{{ network.name }}</span> -->
      </ShareNetwork>
    </div>
  </div>
</template>

<script>
import { ShareNetwork } from 'vue-social-sharing'
import { mapState } from 'vuex'
import TelegramSvg from '../Icons/Telegram'
import WhatsappSvg from '../Icons/Whatsapp'
import FacebookSvg from '../Icons/Facebook'
import LineSvg from '../Icons/Line'

export default {
  components: {
    ShareNetwork,
    TelegramSvg,
    WhatsappSvg,
    FacebookSvg,
    LineSvg,
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    price: {
      type: String,
    },
  },
  name: 'SocialSharing',
  data () {
    return {
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'test', color: '#1877f2' },
        { network: 'line', name: 'Line', icon: 'fab fah fa-lg fa-line', color: '#00c300' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
        { network: 'telegram', name: 'Telegram', icon: 'icon-telegram', color: '#0088cc' },
      ],
      value: '',
    }
  },
  computed: {
    ...mapState('user', {
      store_url: state => state.store_url,
    }),
    ...mapState('product', {
      detail: state => state.productDetail,
    }),

    /**
     * @returns {string}
     */
    sharingUrl() {
      return `${this.store_url}/product/${this.id}`
    },

    /**
     * @returns {string}
     */
    sharingTitle() {
      return `${this.name}. \n`
    },

    /**
     * @returns {string}
     */
    sharingDescription() {
      if (this.value) {
        return this.value
      } else {
        return `${this.detail.description[0].shortDesc}. \n\n Harga: ${this.price}. \n Berat: ${this.detail.catalog_list[0].product_weight} gram`
      }
    },
  },
}
</script>
