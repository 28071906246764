<template>
  <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
    <path
      d="M9.417 15.181l-.397 5.584c.568 0 .814-.244 1.109-.537l2.663-2.545 5.518 4.041c1.012.564 1.725.267 1.998-.931L23.93 3.821l.001-.001c.321-1.496-.541-2.081-1.527-1.714l-21.29 8.151c-1.453.564-1.431 1.374-.247 1.741l5.443 1.693L18.953 5.78c.595-.394 1.136-.176.691.218z"
      fill="#039be5"
    />
  </svg>
</template>

<script>
export default {
  name: 'TelegramSvg',
}
</script>
